// @ts-ignore
import { Elm } from '../src/Main.elm'

// Initial data passed to Elm (should match `Flags` defined in `Shared.elm`)
// https://guide.elm-lang.org/interop/flags.html
var flags = null

// Start our Elm application
var app = Elm.Main.init({ flags: flags })

app.ports.addMercadoPagoButton.subscribe((preferenceId: string) => {
    // Port may be called before Virtual DOM rendering. Loop until element is found
    const interval = setInterval(() => {
        let element: HTMLElement;
        if (element = document.getElementById("mp-button")) {
            element.appendChild(mpButtonScript(preferenceId))
            clearInterval(interval);
        }
    }, 1);
}) 

app.ports.hidePaymentMethods.subscribe((hide: boolean) => {
    
    const paymentMethods = document.getElementById("payment-methods")
    const cashPayment = document.getElementById("cash-payment")

    setTimeout(() => {
        if(hide) {
            paymentMethods.style.display = "none"
            cashPayment.style.display = "block"
        } else {
            paymentMethods.style.display = "block"
            cashPayment.style.display = "none"
        }
    }, 500)
}) 

// Ports go here
// https://guide.elm-lang.org/interop/ports.html

const mpButtonScript = (preferenceId: string) => {
    const node = document.createElement('script')
    node.setAttribute("src", "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js")
    node.setAttribute("data-preference-id", preferenceId)
    node.setAttribute("data-button-label", "Tarjeta o MercadoPago")
    // node.setAttribute("data-elements-color", "")
    return node
};